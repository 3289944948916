<template>
  <div class="content sc-width d-flex advance">
    <user-left-menu/>
    <Row :gutter="50">
      <i-col span="24">
        <Form
          ref="form"
          :model="form"
          :rules="rules"
          style="padding-left:15px;width:50%"
          :label-width="250"
          class="register-form"
        >
          <FormItem label="Current Password" prop="old_password">
            <Input type="password" v-model="form.old_password" />
          </FormItem>

          <FormItem label="New Password" prop="new_password">
            <Input type="password" v-model="form.new_password" />
          </FormItem>

          <FormItem label="Re-type New Password" prop="re_password">
            <Input type="password" v-model="form.re_password" />
          </FormItem>

          <div
            class="update-info search-button"
            :loading="editLoading"
            @click="editSubmit"
          >
            Update Password
          </div>
        </Form>
      </i-col>
    </Row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import api from "@/api";
const { _resetPassword } = api;
import UserLeftMenu from "@/components/userLeftMenu";
export default {
  components: {
   UserLeftMenu
  },
  computed: mapState("user", ["avatar"]),

  data() {
    return {
      editLoading: false,
      form: {
        old_password: "",
        new_password: "",
        re_password: ""
      },
      rules: {
        old_password: [
          {
            required: true,
            message: "Please fill"
          },
          {
            min: 6,
            message: "No less than 6 characters"
          }
        ],
        new_password: [
          {
            required: true,
            message: "Please fill"
          },
          {
            min: 6,
            message: "No less than 6 characters"
          }
        ],
        re_password: [
          {
            required: true,
            message: "Please fill"
          },
          {
            min: 6,
            message: "No less than 6 characters"
          },
          {
            message: "The confirmation password is not identical",
            validator: (rule, value) => {
              return value === this.form.new_password;
            }
          }
        ]
      }
    };
  },
  methods: {
    editSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.editLoading = true;
          _resetPassword({
            new_password: this.form.new_password,
            old_password: this.form.old_password
          })
            .then(() => {
              this.editLoading = false;
              this.$Message.success("success");
            })
            .catch(({ message }) => {
              this.editLoading = false;
              this.$Message.error(message);
            });
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
  .d-flex{
    display: flex;
  }
  .content {
    margin: 60px auto 40px auto;
    /*box-shadow: 0 2px 16px grey, 0 0 1px grey, 0 0 1px grey;*/
    /*height: 560px;*/
    /*background-image: url('../../assets/my-profile.png');*/
    border-radius: 15px;
    /*background: #fff;*/
    padding: 40px 10px 10px 10px;
    .ivu-row{
      background: #fff;
      margin-left: 40px !important;
      border-radius: 15px;
      padding: 20px;
    }
  }
.update-info {
  margin-bottom: 10px;
  width: 220px;
  height: 36px;
  background: #3E1DCE;
  border-radius: 5px;
  font-size: 17px;

  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
  text-align: center;
  padding-top: 9px;
  margin-left: 40px;
}
</style>
<style scoped>
.form-box >>> .ivu-card-bordered {
  border: none;
}
.register-form >>> .ivu-input {
  height: 38px;
  width: 340px;
  border-radius: 10px;
}
.register-form >>> .ivu-select-input {
  height: 36px;
}
.register-form >>> .ivu-form-item-label {
  font-size: 18px;

  font-weight: 500;
  color: #292929;
  line-height: 18px;
}
.register-form >>> .input-note textarea {
  height: 100px;
}
</style>
<style>
  .advance .ivu-row{
    width: 1240px;
    padding-top: 40px !important;
  }
</style>
